import * as zod from "zod";

import { ResizePosition as ResizePositionEnum } from "./types";

function preprocessToInt(arg: unknown) {
    const parsed = parseInt(arg + "", 10);
    return Number.isNaN(parsed) ? arg : parsed;
}

const ResizePositionZod = zod.preprocess(preprocessToInt, zod.nativeEnum(ResizePositionEnum));
const PositiveIntegerZod = zod.preprocess(preprocessToInt, zod.number().positive().int());
const IntegerZod = zod.preprocess(preprocessToInt, zod.number().int());

const AssetOptionsFormatZod = zod.enum(["svg", "pdf"]);

export const AssetOptionsZod = zod
    .object({
        resizePosition: ResizePositionZod.optional(),
        resizeWidth: PositiveIntegerZod.optional(),
        resizeHeight: PositiveIntegerZod.optional(),
        extractTop: IntegerZod.optional(),
        extractLeft: IntegerZod.optional(),
        extractWidth: PositiveIntegerZod.optional(),
        extractHeight: PositiveIntegerZod.optional(),
        /**
         * Indicates whether any transformations should be applied to asset
         * It takes only value of `false`
         */
        format: AssetOptionsFormatZod.optional(),
    })
    .strict();

export type AssetOptions = zod.infer<typeof AssetOptionsZod>;
