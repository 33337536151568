import { Either, fromTry, right, left } from "@sweet-monads/either";
import zod from "zod";

import { Bucket, BucketValues } from "./Bucket";
import { Key } from "./Key";
import { base64decode, base64encode } from "./base64";
import { Edits } from "./types";
import { getDomain } from "./utils/getDomain";
import { getErrorMessageFromZodError } from "./utils/getErrorMessageFromZodError";

const internalDataValidation = zod
    .object({
        bucket: zod.enum(BucketValues).optional(),
        key: zod.string().transform((val, ctx) => {
            const result = Key.fromString(val);
            if (result.isLeft()) {
                ctx.addIssue({
                    code: zod.ZodIssueCode.custom,
                    message: result.value,
                });
                return;
            }
            return result.value;
        }),
        edits: zod.object({}).passthrough().optional(),
    })
    .strict();

function parseUrlData(data: string): Either<string, InternalData> {
    return fromTry(() => {
        return base64decode(data);
    })
        .chain((x) =>
            fromTry(() => {
                return JSON.parse(x);
            })
        )
        .mapLeft(() => "Cannot parse path")
        .chain((data) => {
            const result = internalDataValidation.safeParse(data);
            if (!result.success) {
                return left(getErrorMessageFromZodError(result.error));
            }
            return right(
                new VaultUrl({
                    bucket: result.data.bucket,
                    edits: result.data.edits,
                    key: result.data.key!,
                })
            );
        });
}

/**
 * URL Object used to describe final URL for image handler service
 *
 * Example URL:
 * https://images.holibob.tech/eyJrZXkiOiJwcm9kdWN0SW1hZ2VzL2JjMTdkM2M5LTdhNmYtNGEwYS1iMjBjLWNkN2ExYWYwNzhhNCIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb3ZlciIsIndpZHRoIjo0MDk2fX19
 *
 * That represents following setup:
 * bucket: images.holibob.tech
 * key: productImages/bc17d3c9-7a6f-4a0a-b20c-cd7a1af078a4
 * edits: {"resize":{"fit":"cover","width":4096}}}
 */
export class VaultUrl {
    readonly bucket?: Bucket;
    readonly key!: Key;
    readonly edits?: Edits;
    readonly cacheBusting?: string;

    constructor(data: UrlData) {
        Object.assign(this, data);
        Object.freeze(this);
    }

    modify(data: Partial<UrlData>) {
        return new VaultUrl({
            ...this,
            ...data,
        });
    }

    modifyEdits(edits: Partial<Edits>) {
        return new VaultUrl({
            ...this,
            edits: {
                ...this.edits,
                ...edits,
            },
        });
    }

    toString() {
        return this.toURL().toString();
    }

    toURL() {
        const url = new URL("", `https://${getDomain("with-transformations")}`);
        const base64Data = {
            bucket: this.bucket,
            edits: this.edits,
            key: this.key.toString(),
            ...(this.cacheBusting && { cacheBusting: this.cacheBusting }),
        };

        url.pathname = base64encode(JSON.stringify(base64Data));
        return url;
    }

    setCacheBusting(value: string) {
        return new VaultUrl({ ...this, cacheBusting: value });
    }

    static fromString(url: string): Either<string, VaultUrl> {
        return fromTry(() => {
            return new URL(url);
        })
            .mapLeft(() => "Invalid URL")
            .chain((url) => {
                if (url.host !== getDomain("with-transformations")) {
                    return left("Invalid URL Domain");
                }

                if (!url.pathname || url.pathname === "/") {
                    return left("Invalid path");
                }

                return parseUrlData(url.pathname.replace(/^\//, "")).map((data) => {
                    return new VaultUrl(data);
                });
            });
    }
}

export type InternalData = {
    bucket?: Bucket;
    key: Key;
    edits?: Edits;
};

export type UrlData = Pick<VaultUrl, "bucket" | "key" | "edits" | "cacheBusting">;
