import { useMemo } from "react";
import { createContext } from "react";

import type { Viewer } from "@holibob-packages/graphql-types";
import { DEFAULT_LANGUAGE, Language } from "@holibob-packages/language";

const DEFAULT_CURRENCY = "GBP";

export type LocalizationContextValue = {
    language: Language;
    currency: string;
    viewer: Pick<Viewer, "supportedLanguages" | "language">;
};
const localizationContextDefaultValue = {
    currency: DEFAULT_CURRENCY,
    language: DEFAULT_LANGUAGE,
    viewer: {
        supportedLanguages: [DEFAULT_LANGUAGE],
        language: DEFAULT_LANGUAGE,
    },
};
export const LocalizationContext = createContext<LocalizationContextValue>(localizationContextDefaultValue);

export type LocalizationProviderProps = {
    children: React.ReactNode;
} & LocalizationContextValue;
export const LocalizationProvider = ({ children, language, currency, viewer }: Partial<LocalizationProviderProps>) => {
    const value = useMemo<LocalizationContextValue>(() => {
        return {
            currency: currency ?? localizationContextDefaultValue.currency,
            language: language ?? localizationContextDefaultValue.language,
            viewer: viewer ?? localizationContextDefaultValue.viewer,
        };
    }, [currency, language, viewer]);

    return <LocalizationContext.Provider value={value}>{children}</LocalizationContext.Provider>;
};
