import { useParams } from "next/navigation";

export function useClientLocale(): string | undefined {
    const params = useParams();

    if (params?.locale) {
        return params.locale as string;
    }

    return undefined;
}
