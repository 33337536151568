import { Either, left, right } from "@sweet-monads/either";
import zod from "zod";

import { AssetOptions, AssetOptionsZod } from "./AssetOptions";
import { AssetType, AssetTypeZod } from "./AssetType";
import { getErrorMessageFromZodError } from "./utils/getErrorMessageFromZodError";

const idSchema = zod.string().uuid();
const assetUrlZod = zod.object({
    type: AssetTypeZod,
    id: zod.string().min(1),
    options: AssetOptionsZod.optional(),
});

type RawAssetUrlData = {
    id: string;
    type: string;
    searchParams?: URLSearchParams;
};

const ASSET_REGEXP = /^asset:\/\/([a-z0-9-]+)\/([a-z0-9-./]+)(\?.*)?$/i;

function parseUrlToRawAssetUrlData(url: string): Either<string, RawAssetUrlData> {
    const data = ASSET_REGEXP.exec(url);

    if (!data) {
        return left("Invalid URL");
    }

    return right({
        type: data[1]!,
        id: data[2]!,
        searchParams: data[3] ? new URLSearchParams(data[3]) : undefined,
    });
}

function urlToAssetUrl(url: RawAssetUrlData): Either<string, AssetUrl> {
    const options = url.searchParams ? Object.fromEntries(url.searchParams.entries()) : {};
    const urlData = assetUrlZod.safeParse({
        type: url.type,
        id: url.id.replace(/^\//, ""),
        options: Object.keys(options).length > 0 ? options : undefined,
    });

    if (!urlData.success) {
        return left(getErrorMessageFromZodError(urlData.error));
    }

    return right(new AssetUrl(urlData.data));
}

export type AssetData = Pick<AssetUrl, "type" | "id" | "options">;

/**
 * Abstract asset URL
 *
 * This contains minimal information about the asset: its type, id and optional options on which we construct final Url
 */
export class AssetUrl {
    readonly type!: AssetType;
    readonly id!: string;
    readonly options?: AssetOptions;

    constructor(data: AssetData) {
        Object.assign(this, data);
        Object.freeze(this);
    }

    static fromString(url: string): Either<string, AssetUrl> {
        return parseUrlToRawAssetUrlData(url)
            .mapLeft((_e) => {
                return "Invalid URL";
            })
            .chain(urlToAssetUrl);
    }

    modify(data: Partial<AssetData>) {
        return new AssetUrl({
            ...this,
            ...data,
        });
    }

    modifyOptions(options: Partial<AssetOptions>) {
        return new AssetUrl({
            ...this,
            options: {
                ...this.options,
                ...options,
            },
        });
    }

    toString() {
        const base = `asset://${this.type}/${this.id}`;
        if (this.options) {
            const searchParams = new URLSearchParams({});
            for (const [key, value] of Object.entries(this.options)) {
                searchParams.set(key, value + "");
            }

            return base + "?" + searchParams;
        }
        return base;
    }

    static enforceUrlForIdOrUrl(idOrUrl: string, data: Omit<AssetData, "id">): Either<string, AssetUrl> {
        if (idSchema.safeParse(idOrUrl).success) {
            return right(
                new AssetUrl({
                    ...data,
                    id: idOrUrl,
                })
            );
        } else if (idOrUrl.startsWith("asset://")) {
            return AssetUrl.fromString(idOrUrl);
        }

        return left("Provided string is not an id or asset url");
    }
}
