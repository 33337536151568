/**
 * Optimized base64 decode using atob on the browser rather than shipping node_modules/buffer to the browser
 */
export const base64decode =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: cannot find name 'window'
    typeof window === "undefined"
        ? // nodejs
          (str: string) => Buffer.from(str, "base64").toString("utf8")
        : // browser
          (str: string) => atob(str);

/**
 * Optimized base64 encode using btoa on the browser rather than shipping node_modules/buffer to the browser
 */
export const base64encode =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: cannot find name 'window'
    typeof window === "undefined"
        ? // nodejs
          (str: string) => Buffer.from(str).toString("base64")
        : // browser
          (str: string) => btoa(str);
