import { Either, left, right } from "@sweet-monads/either";
import zod from "zod";

import { Folder, FolderZod } from "./Folder";
import { getErrorMessageFromZodError } from "./utils/getErrorMessageFromZodError";

const keyStructValidation = zod.object({
    folder: FolderZod,
    id: zod.string().uuid(),
});

const keyStringValidation = zod
    .string()
    .trim()
    .transform((val, ctx) => {
        const [folder, id] = val.split("/");

        const result = keyStructValidation.safeParse({
            folder,
            id,
        });
        if (!result.success) {
            ctx.addIssue({
                code: zod.ZodIssueCode.custom,
                message: getErrorMessageFromZodError(result.error),
            });
            return;
        }
        return result.data;
    });

export class Key {
    readonly folder: Folder;
    readonly id: string;

    constructor(folder: Folder, id: string) {
        this.folder = folder;
        this.id = id;
        Object.freeze(this);
    }

    toString() {
        return `${this.folder}/${this.id}`;
    }

    static fromString(input: string): Either<string, Key> {
        const result = keyStringValidation.safeParse(input);
        if (result.success) {
            return right(new Key(result.data!.folder, result.data!.id));
        }
        return left(getErrorMessageFromZodError(result.error));
    }
}
