import { ZodError } from "zod";

export function getErrorMessageFromZodError(error: ZodError) {
    const flattenErrors = error.flatten();
    return Object.entries(flattenErrors.fieldErrors)
        .flatMap(([fieldName, errors]) => {
            return `Invalid ${fieldName}: ${(errors ?? []).join(" ")}`;
        })
        .concat(flattenErrors.formErrors)
        .join(" ");
}
