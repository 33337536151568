import { LanguageCode } from "@holibob-packages/graphql-types";

export type Language<
    Id extends string = string,
    Code extends string = string,
    CodeShort extends string = string,
    URLCode extends string = string,
    Name extends string = string,
> = {
    readonly id: Id;
    readonly code: Code;
    readonly codeShort: CodeShort;
    readonly urlCode: URLCode;
    readonly name: Name;
};

export class LanguageStruct<
    Id extends string = string,
    Code extends string = string,
    CodeShort extends string = string,
    URLCode extends string = string,
    Name extends string = string,
> implements Language<Id, Code, CodeShort, URLCode, Name>
{
    readonly graphQLCode: LanguageCode;

    constructor(
        readonly id: Id,
        readonly code: Code,
        readonly codeShort: CodeShort,
        readonly urlCode: URLCode,
        readonly name: Name
    ) {
        this.graphQLCode = code.replace(/-/g, "_") as LanguageCode;
    }

    toString() {
        return this.code;
    }

    toJSON() {
        return this.code;
    }

    /**
     * Check if the language matches a given code
     */
    matchesCode(codeOrShortCode: string): boolean {
        const code = codeOrShortCode.toLowerCase();
        return this.code.toLowerCase() === code || this.codeShort.toLowerCase() === code;
    }
}
