import * as zod from "zod";

export const AssetTypeZod = zod.enum(
    [
        "heroImage",
        "distributionChannelPageImage",
        "distributionChannelAuthorAvatarImage",
        "hierarchyCurationMetaImage",
        "distributionChannelPageCategoryImage",
        "hbmlImage",
        "hbmlSectionImage",
        "hbmlStackItemImage",
        "heroVideo",
        "productImage",
        "font",
        "entityContent",
        "brandVoucherLogo",
        "brandFavicon",
        "brandLogo",
        "openGraphImage",
        "translationImport",
        "bookingQuestion",
        "entityAttachment",
        "productAttachment",
        "avatar",
        "organizationLogo",
        "organizationBannerImage",
        "systemTagIcon",
        "staticImage",
    ],
    {
        errorMap: (issue, ctx) => {
            if (issue.code === "invalid_enum_value") {
                return {
                    message: `Invalid asset type: ${issue.received}`,
                };
            }
            return { message: ctx.defaultError };
        },
    }
);
export type AssetType = zod.infer<typeof AssetTypeZod>;
