import * as zod from "zod";

export const FolderZod = zod.enum([
    "productImages",
    "vaultFiles",
    "font",
    "svg",
    "entityContent",
    "static/image",
    "bookingQuestion",
]);
export type Folder = zod.infer<typeof FolderZod>;
