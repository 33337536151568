import type { ResizeOptions, Region, FormatEnum } from "sharp";

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

/**
 * Some edits might be missing. In that case please add them.
 */
export type Edits = {
    resize?: EditsResize;
    crop?: Region;
    extract?: Region;
    toFormat?: keyof FormatEnum;
};

export type EditsResize = ResizeOptions & { position?: ResizePosition };

// "assembled" from https://github.com/lovell/sharp/blob/main/lib/resize.js
// position + gravity + strategy
export enum ResizePosition {
    CENTER = 0,
    TOP = 1,
    NORTH = 1,
    RIGHT = 2,
    EAST = 2,
    BOTTOM = 3,
    SOUTH = 3,
    LEFT = 4,
    WEST = 4,
    RIGHT_TOP = 5,
    NORTH_EAST = 5,
    RIGHT_BOTTOM = 6,
    SOUTH_EAST = 6,
    LEFT_BOTTOM = 7,
    SOUTH_WEST = 7,
    LEFT_TOP = 8,
    NORTH_WEST = 8,

    ENTROPY = 16,
    ATTENTION = 17,
}
