// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- It's fine here
import type { LinkProps } from "next/link";
import type { UrlObject } from "node:url";

import type { Viewer } from "@holibob-packages/graphql-types";

import { hasPathnamePrefixed } from "./hasPathnamePrefixed";

type Href = LinkProps["href"];

export class URLLocalizer {
    constructor(private options: Pick<Viewer, "supportedLanguages" | "language">) {}

    shouldLocalize() {
        return this.options.supportedLanguages.length > 1;
    }

    localizePathname(locale: string, pathname: string) {
        if (!this.shouldLocalize()) {
            return pathname;
        }

        let localizedHref = "/" + locale;

        if (pathname !== "/") {
            localizedHref += pathname;
        }
        if (localizedHref.endsWith("/")) {
            localizedHref = localizedHref.slice(0, -1);
        }

        return localizedHref;
    }

    unlocalizePathname(pathname: string, locale: string) {
        if (!this.shouldLocalize()) {
            return pathname;
        }

        return pathname.replace(new RegExp(`^/${locale}`), "") || "/";
    }

    getLocaleFromPathname(pathname: string): string | undefined {
        const candidate = pathname.split("/")[1];

        if (this.shouldLocalize()) {
            return candidate;
        } else if (this.options.language.urlCode === candidate) {
            return candidate;
        } else {
            return undefined;
        }
    }

    localizeHref(href: string, locale: string, defaultLocale: string, pathname: string | null): string;
    localizeHref(
        href: UrlObject | string,
        locale: string,
        defaultLocale: string,
        pathname: string | null
    ): UrlObject | string;
    localizeHref(href: UrlObject | string, locale: string, defaultLocale: string = locale, pathname: string | null) {
        if (!isLocalHref(href) || isRelativeHref(href) || !this.shouldLocalize()) {
            return href;
        }

        const isSwitchingLocale = locale !== defaultLocale;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const isPathnamePrefixed = locale == null || hasPathnamePrefixed(locale, pathname);
        const shouldPrefix = isPathnamePrefixed || isSwitchingLocale;

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (shouldPrefix && locale != null) {
            return this.prefixHref(href, locale);
        }

        return href;
    }

    prefixHref(href: string, locale: string): string;
    prefixHref(href: UrlObject | string, locale: string): UrlObject | string;
    prefixHref(href: UrlObject | string, locale: string) {
        let prefixedHref;
        if (typeof href === "string") {
            prefixedHref = this.localizePathname(locale, href);
        } else {
            prefixedHref = { ...href };
            if (href.pathname) {
                prefixedHref.pathname = this.localizePathname(locale, href.pathname);
            }
        }

        return prefixedHref;
    }
}

function isRelativeHref(href: Href) {
    const pathname = typeof href === "object" ? href.pathname : href;
    return pathname != null && !pathname.startsWith("/");
}

function isLocalHref(href: Href) {
    if (typeof href === "object") {
        return href.host == null && href.hostname == null;
    } else {
        const hasProtocol = /^[a-z]+:/i.test(href);
        return !hasProtocol;
    }
}
